<template>
  <div class="rearuit">
    <div class="header">
      <div class="conter">
        <div>寻找最亮的你</div>
        <p>把我机遇，与成功并肩</p>
      </div>
    </div>
    <div class="conter">
      <div class="rearuit-header">
        <h1>入职福利</h1>
        <p>IMAGE CLOUD APPLICATION</p>
        <div class="welfare">
          <div class="item" v-for="(item, index) in welfareList" :key="index">
            <img :src="item.url" alt="" />
            <p>{{ item.text }}</p>
          </div>
        </div>
        <div class="table">
          <h1>招聘信息</h1>
          <el-table :data="tableData" style="width: 100%" stripe>
            <el-table-column type="expand" width="100">
              <template slot-scope="props">
                <div style="margin-left: 60px">
                  <h4 style="">工作职责:</h4>
                  <div class="ql-editor" v-html="props.row.dutyDate"></div>
                  <h4>岗位要求：</h4>
                  <div class="ql-editor" v-html="props.row.claimData"></div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="招聘岗位" prop="title"> </el-table-column>
            <el-table-column label="工作地点" prop="address"> </el-table-column>
            <el-table-column label="发布日期" prop="startTime">
            </el-table-column>
            <el-table-column label="截止日期" prop="endTime"> </el-table-column>
          </el-table>
        </div>
        <div class="contact">
          <h1>联系方式</h1>
          <a @click="download">点击下载岗位申请表</a>
          <div>
            <p class="mode">联系电话</p>
            <div class="num">028-8532 6217</div>
          </div>
          <div>
            <p class="mode">应聘方式</p>
            <div class="num">
              <p>编写邮件主题“姓名-应聘职位”</p>
              <p>在截止日期前发送至 hr@scknot.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer style="margin-top: 100px" />
  </div>
</template>

<script>
import Footer from '../../components/webFooter/footerBottom.vue'
import { getRecruitInfo } from '../../api/journalism'
// const applicationFile =
//   'https://knot.oss-cn-hangzhou.aliyuncs.com/pdf/ApplicantForm.docx' //  下载地址
const applicationFile =
  'https://sichuan-2.zos.ctyun.cn/knot/pdf/ApplicantForm.docx' //  天翼云下载地址
export default {
  components: {
    Footer
  },
  data() {
    return {
      welfareList: [
        {
          text: '优于同行薪资',
          url: require('../../assets/images/web/recruit/ty.png')
        },
        {
          text: '期权激励',
          url: require('../../assets/images/web/recruit/jl.png')
        },
        {
          text: '餐食补助',
          url: require('../../assets/images/web/recruit/cb.png')
        },
        {
          text: '通勤补助',
          url: require('../../assets/images/web/recruit/tq.png')
        },
        {
          text: '带薪年假',
          url: require('../../assets/images/web/recruit/nj.png')
        }
      ],
      tableData: []
    }
  },
  methods: {
    async getRecruitInfoList() {
      const params = { recruitType: '社会招聘', pageNumber: 1, pageSize: 12 }
      const { code, data } = await getRecruitInfo(params)
      if (code === '0000') {
        this.tableData = data.recruitInfo
        this.tableData.forEach((item) => {
           item.dutyDate = item.dutyDate.replaceAll('<strong', '<b')
           item.dutyDate = item.dutyDate.replaceAll('</strong', '</b')
           item.claimData = item.claimData.replace('<strong', '<b')
           item.claimData = item.claimData.replace('</strong', '</b')
        })
        // for (var item of this.tableData) {

        // }
        console.log(data.recruitInfo)
      }
    },
    download() {
      window.open(applicationFile)
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 2) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    }
  },
  created() {
    this.getRecruitInfoList()
  }
}
</script>

<style lang="less" scoped>
.rearuit {
  width: 100%;
  height: 100%;
  .header {
    width: 100%;
    height: 480px;
    background: url('../../assets/images/web/recruit/xz.png') no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    .conter {
      padding-left: 250px;
      box-sizing: border-box;
      width: 1200px;
      div {
        padding-top: 140px;
        font-size: 74px;
        color: #fff;
        font-family: Source Han Sans CN;
        letter-spacing: 4px;
        font-weight: 500;
        text-shadow: 8px 16px 27px rgba(18, 40, 78, 0.68);
      }
      p {
        margin: 0;
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding-top: 50px;
        line-height: 47px;
        text-shadow: 8px 16px 27px rgba(18, 40, 78, 0.68);
      }
    }
  }
  .rearuit-header {
    > h1 {
      text-align: center;
      width: 1200px;
      font-size: 52px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      padding: 78px 0 0px 0;
    }
    > p {
      text-align: center;
      font-size: 20px;
      font-family: AngsanaUPC;
      font-weight: 400;
      color: #b5b5b5;
    }
    .welfare {
      display: flex;
      justify-content: center;
      padding-top: 100px;
      box-sizing: border-box;
      width: 1200px;
      text-align: center;
      .item {
        padding-right: 120px;
        img {
          width: 132px;
          height: 132px;
        }
        p {
          width: 150px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #29262a;
        }
      }
      .item:last-child {
        padding: 0;
      }
    }
    .table {
      padding: 80px 0;
      h1 {
        float: left;
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #252525;
      }
      h4 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        padding: 10px 0;
      }
    }
    .contact {
      h1 {
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #252525;
      }
      a {
        margin-top: 35px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0c2948;
        cursor: pointer;
      }
      div {
        display: flex;
        margin: 26px 0;
        .mode {
          width: 250px;
          height: 117px;
          background: #0c2948;
          line-height: 117px;
          text-align: center;
          color: #fff;
        }
        .num {
          margin: 0;
          width: 100%;
          padding-left: 173px;
          justify-content: center;
          background: #f1f1f1;
          opacity: 0.77;
        }
        .num:nth-child(2) {
          margin-top: 16px;
          display: flex;
          width: 100%;
          height: 117px;
          flex-direction: column;

          p {
            // line-height: 53px;
          }
        }
      }
    }
  }
  .footer[data-v-e181d294] {
    height: 0;
    background-color: #040c1b;
  }
  /deep/th {
    background: #000000;
    opacity: 0.59;
    color: #fff;
  }
}
</style>
